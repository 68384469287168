<template>
  <div class="admin-content">
    <div class="admin-header-menu">
      <h1>Select location</h1>
      <div class="buttons">
        <button
          class="edit white"
          v-if="!drawing"
          @mousedown.prevent="drawBoundary"
        >
          Start drawing
        </button>
        <button
          class="edit white"
          v-if="drawing"
          @mousedown.prevent="stopDrawing"
        >
          Stop drawing
        </button>
        <button @mousedown.prevent="back">Done</button>
      </div>
    </div>
    <EditSite
      v-if="site"
      ref="editSite"
      :site="site"
      @save="stopDrawing"
      @create="addSiteToProject"
    />
  </div>
</template>

<script>
import EditSite from "../../components/EditSite";
export default {
  components: { EditSite },
  name: "AdminProjectLocation",
  data: function () {
    return {
      site: null,
      drawing: false,
    };
  },
  created: function () {
    if (typeof this.$store.state.currentProject.site == "string") {
      this.$store
        .dispatch("loadSite", {
          _id: { $oid: this.$store.state.currentProject.site },
        })
        .then((site) => {
          this.site = site;
        });
    } else if (this.$store.state.currentProject.site) {
      this.$store
        .dispatch("loadSite", {
          _id: { $oid: this.$store.state.currentProject.site._id.toString() },
        })
        .then((site) => {
          this.site = site;
        });
    } else {
      this.site = {
        name: this.$store.state.currentProject.name + " site",
        projectId: this.$store.state.currentProject._id.toString()
      };
    }
  },
  methods: {
    drawBoundary() {
      this.$refs.editSite.startDrawing();
      this.drawing = true;
    },
    stopDrawing() {
      this.$refs.editSite.stopDrawing();
      this.drawing = false;
    },
    addSiteToProject(site) {
      this.$store
        .dispatch("updateProjectKey", {
          project: this.$store.state.currentProject,
          method: "$set",
          key: "site",
          data: site._id.toString(),
        })
        .then(() => {
          this.site = site;
          this.drawing = false;
        })
        .catch((e) => {
          this.error = `Unable to save project site: ${e}`;
        });
    },
    back() {
      this.$router.push({ path: "/Admin/ProjectDashboard" });
    },
  },
};
</script>

<style>
@import "../../styles/admin.scss";

.admin-content {
  #map-wrapper {
    margin-top: 85px;
  }
}

.error {
  position: fixed;
  top: 10px;
  left: 30px;
  background: red;
  color: white;
}

#scenario-details {
  background-color: white;
  position: absolute;
  top: 60px;
  right: 50px;
  padding: 15px;
  border-radius: 3px;
}
#scenario-details textarea.form-control {
  height: 250px;
}

#scenario-details .target textarea.form-control {
  height: 120px;
}
</style>
